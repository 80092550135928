export function addToCalendar(title: string, description: string, date: Date) {
  // Format date to ISO string and remove milliseconds
  const formattedDate = date.toISOString().replace(/\.\d{3}/, '');
  
  // Create calendar event URL
  const eventUrl = new URL('https://calendar.google.com/calendar/render');
  eventUrl.searchParams.append('action', 'TEMPLATE');
  eventUrl.searchParams.append('text', title);
  eventUrl.searchParams.append('details', description);
  eventUrl.searchParams.append('dates', `${formattedDate}/${formattedDate}`);
  
  // Open calendar in new window
  window.open(eventUrl.toString(), '_blank');
}