import { useEffect } from 'react';
import { useVehicleStore } from '../store/vehicleStore';
import { useServiceStore } from '../store/serviceStore';
import { useAuth } from './useAuth';

export function useInitialData() {
  const { user } = useAuth();
  const { fetchVehicles } = useVehicleStore();
  const { fetchServices } = useServiceStore();

  useEffect(() => {
    if (user) {
      fetchVehicles();
      fetchServices();
    }
  }, [user]);
}