import React, { useState } from 'react';
import { Car, Trash2 } from 'lucide-react';
import { useVehicleStore } from '../store/vehicleStore';
import { VehicleForm } from './vehicles/VehicleForm';
import { VehicleCard } from './vehicles/VehicleCard';

export function VehicleDetails() {
  const { vehicles } = useVehicleStore();
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
          <Car className="w-6 h-6" />
          My Vehicles
        </h2>
        <button
          onClick={() => setShowForm(!showForm)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          {showForm ? 'Cancel' : 'Add Vehicle'}
        </button>
      </div>

      {showForm && <VehicleForm onComplete={() => setShowForm(false)} />}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {vehicles.map((vehicle) => (
          <VehicleCard key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
    </div>
  );
}