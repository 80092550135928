/**
 * @author JS (Jayden Scahill)
 */

import { useState, useEffect } from 'react';

export function useTheme() {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem('theme');
      if (saved === 'dark' || saved === 'light') return saved;
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    return 'light';
  });

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    root.classList.add(theme);
    localStorage.setItem('theme', theme);

    // Force a re-render of images by adding a timestamp to the src
    const images = document.querySelectorAll('img[src*="omvs-logo"]');
    images.forEach(img => {
      const currentSrc = (img as HTMLImageElement).src.split('?')[0];
      (img as HTMLImageElement).src = `${currentSrc}?t=${Date.now()}`;
    });
  }, [theme]);

  const toggleTheme = () => setTheme(prev => prev === 'light' ? 'dark' : 'light');

  return { theme, toggleTheme };
}