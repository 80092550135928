import React, { useState } from 'react';
import { useVehicleStore } from '../../store/vehicleStore';
import type { Vehicle } from '../../types';

interface VehicleFormProps {
  vehicle?: Vehicle;
  onComplete: () => void;
  isEditing?: boolean;
}

export function VehicleForm({ vehicle, onComplete, isEditing = false }: VehicleFormProps) {
  const { addVehicle, updateVehicle } = useVehicleStore();
  const [formData, setFormData] = useState<Partial<Vehicle>>(vehicle || {});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const vehicleData: Omit<Vehicle, 'id'> = {
      make: formData.make || '',
      model: formData.model || '',
      year: formData.year || 0,
      vin: formData.vin || '',
      licensePlate: formData.licensePlate || '',
      mileage: formData.mileage || 0,
      purchaseDate: formData.purchaseDate || '',
      insuranceInfo: formData.insuranceInfo || '',
      notes: formData.notes || ''
    };

    if (isEditing && vehicle) {
      await updateVehicle(vehicle.id, vehicleData);
    } else {
      await addVehicle(vehicleData);
    }
    onComplete();
  };

  return (
    <form onSubmit={handleSubmit} className="form-container p-6 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="form-label">Make *</label>
          <input
            type="text"
            value={formData.make || ''}
            onChange={(e) => setFormData({ ...formData, make: e.target.value })}
            className="w-full rounded-lg"
            required
          />
        </div>
        
        <div>
          <label className="form-label">Model *</label>
          <input
            type="text"
            value={formData.model || ''}
            onChange={(e) => setFormData({ ...formData, model: e.target.value })}
            className="w-full rounded-lg"
            required
          />
        </div>
        
        <div>
          <label className="form-label">Year *</label>
          <input
            type="number"
            value={formData.year || ''}
            onChange={(e) => setFormData({ ...formData, year: parseInt(e.target.value) })}
            className="w-full rounded-lg"
            required
          />
        </div>
        
        <div>
          <label className="form-label">Current Kilometers/Hours *</label>
          <input
            type="number"
            value={formData.mileage || ''}
            onChange={(e) => setFormData({ ...formData, mileage: parseInt(e.target.value) })}
            className="w-full rounded-lg"
            required
          />
        </div>

        <div>
          <label className="form-label">VIN</label>
          <input
            type="text"
            value={formData.vin || ''}
            onChange={(e) => setFormData({ ...formData, vin: e.target.value })}
            className="w-full rounded-lg"
          />
        </div>
        
        <div>
          <label className="form-label">License Plate</label>
          <input
            type="text"
            value={formData.licensePlate || ''}
            onChange={(e) => setFormData({ ...formData, licensePlate: e.target.value })}
            className="w-full rounded-lg"
          />
        </div>

        <div>
          <label className="form-label">Purchase Date</label>
          <input
            type="date"
            value={formData.purchaseDate || ''}
            onChange={(e) => setFormData({ ...formData, purchaseDate: e.target.value })}
            className="w-full rounded-lg"
          />
        </div>

        <div className="md:col-span-2">
          <label className="form-label">Insurance Information</label>
          <textarea
            value={formData.insuranceInfo || ''}
            onChange={(e) => setFormData({ ...formData, insuranceInfo: e.target.value })}
            rows={3}
            className="w-full rounded-lg"
          />
        </div>

        <div className="md:col-span-2">
          <label className="form-label">Notes</label>
          <textarea
            value={formData.notes || ''}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
            rows={3}
            className="w-full rounded-lg"
          />
        </div>
      </div>
      
      <div className="mt-6 flex justify-end gap-4">
        {isEditing && (
          <button
            type="button"
            onClick={onComplete}
            className="px-4 py-2 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          {isEditing ? 'Save Changes' : 'Add Vehicle'}
        </button>
      </div>
    </form>
  );
}