import React from 'react';

interface InfoSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export function InfoSection({ title, children, className = '' }: InfoSectionProps) {
  return (
    <div className={className}>
      <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-4">
        {title}
      </h4>
      <div className="text-gray-900 dark:text-gray-100">
        {children}
      </div>
    </div>
  );
}