import React, { useState } from 'react';
import { Trash2, Edit2 } from 'lucide-react';
import { useVehicleStore } from '../../store/vehicleStore';
import { VehicleForm } from './VehicleForm';
import { ConfirmDialog } from '../ui/ConfirmDialog';
import type { Vehicle } from '../../types';

interface VehicleCardProps {
  vehicle: Vehicle;
}

export function VehicleCard({ vehicle }: VehicleCardProps) {
  const { deleteVehicle } = useVehicleStore();
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  if (isEditing) {
    return (
      <VehicleForm
        vehicle={vehicle}
        onComplete={() => setIsEditing(false)}
        isEditing
      />
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {vehicle.year} {vehicle.make} {vehicle.model}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {vehicle.licensePlate}
            </p>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setIsEditing(true)}
              className="text-blue-500 hover:text-blue-600"
              aria-label="Edit vehicle"
            >
              <Edit2 className="w-5 h-5" />
            </button>
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="text-red-500 hover:text-red-600"
              aria-label="Delete vehicle"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
        
        <div className="mt-4 space-y-2">
          <p className="text-sm text-gray-600 dark:text-gray-300">
            <span className="font-medium">VIN:</span> {vehicle.vin}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            <span className="font-medium">Kilometers/Hours:</span> {vehicle.mileage.toLocaleString()}
          </p>
        </div>
      </div>

      <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={() => deleteVehicle(vehicle.id)}
        title="Delete Vehicle"
        message="Are you sure you want to delete this vehicle? This will also delete all associated service records. This action cannot be undone."
      />
    </>
  );
}