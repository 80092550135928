import React from 'react';
import { Trash2 } from 'lucide-react';
import { useServiceStore } from '../../store/serviceStore';
import { useVehicleStore } from '../../store/vehicleStore';
import { ServiceCard } from './ServiceCard';

export function ServiceList() {
  const { services } = useServiceStore();
  const { vehicles } = useVehicleStore();

  return (
    <div className="space-y-4">
      {services.map((service) => (
        <ServiceCard 
          key={service.id} 
          service={service}
          vehicle={vehicles.find(v => v.id === service.vehicleId)}
        />
      ))}
    </div>
  );
}