import React, { useState } from 'react';
import { LayoutGrid, Car, Wrench, UserCircle } from 'lucide-react';
import { Navigation } from './navigation/Navigation';
import { VehicleDetails } from './VehicleDetails';
import { ServiceRecords } from './ServiceRecords';
import { PersonalInformation } from './personal/PersonalInformation';
import { DashboardContent } from './dashboard/DashboardContent';
import { useInitialData } from '../hooks/useInitialData';
import type { Tab } from '../types';

const tabs: Tab[] = [
  { id: 'dashboard', name: 'Dashboard', icon: LayoutGrid },
  { id: 'personal', name: 'Personal Info', icon: UserCircle },
  { id: 'vehicles', name: 'My Vehicles', icon: Car },
  { id: 'services', name: 'Service History', icon: Wrench },
];

export function Dashboard() {
  useInitialData();
  const [activeTab, setActiveTab] = useState('dashboard');

  const handleCardClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <Navigation tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {activeTab === 'dashboard' && <DashboardContent onCardClick={handleCardClick} />}
        {activeTab === 'personal' && <PersonalInformation />}
        {activeTab === 'vehicles' && <VehicleDetails />}
        {activeTab === 'services' && <ServiceRecords />}
      </main>
    </div>
  );
}